.pointer {
  cursor: pointer;
}

.tsparticles {
  position: relative;
}

.authContent {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
}

.navbarContainer {
  position: absolute;
  // min-width: 60vw;
  z-index: 30;
  // right: 3%;
  // top: 40%;
}

.navbarposisson {
  backdrop-filter: blur(3px);
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  border-radius: 50px;
  background-color: #58595a0b;
  font-family: fontRegular;
  font-weight: 600;
  font-size: 0.9rem;
  box-shadow: inset -5px -10px 27px -6px rgba(38, 194, 221, 0.458);
}

.navbaritemContainer {
  display: flex;
  padding: 1.3rem 3rem;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.navbarItemPart1 {
  display: flex;
  gap: 2rem;
  width: fit-content;
  justify-content: space-between;
}

.navbaritem {
  display: flex;
  justify-content: center;
  width: fit-content;
  transition: all 0.6s;
  white-space: nowrap;
}

.logoNavbar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  margin: 0 3.5rem;
}

.logoNavbar img {
  width: 2.5rem;
  display: flex;
  justify-content: center;
  // height: 3rem;
}

.navbaritem:hover {
  text-shadow: 0 0 7px #fff, 0 0 1px #fff, 0 0 1px #ffffff, 0 0 20px #ffffff,
    0 0 1px #0073e6, 0 0 10px #0073e6, 0 0 15px #ffffff;
}

.titleAuth {
  display: flex;
  //   justify-content: center;
  flex-direction: column;
  //   align-items: center;
  width: 70vw;
  margin-top: 13%;
}

.titlePart2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: 2.5rem auto;
}

.textTitle {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  // margin-inline-start: 2.5rem;
  text-align: center;
  width: 100%;
  // padding: 0 1rem;
  font-size: 3.3rem;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.358),
    0 0 1px rgba(255, 255, 255, 0.615), 0 0 1px #ffffff61, 0 0 6px #ffffff3a,
    0 0 0px #0073e6, 0 0 3px #0073e682, 0 0 11px #ffffff91;
}

.descriptionTitle {
  // margin-top: 5rem;
  margin-inline-start: 2rem;
  font-family: fontRegular;
  font-size: 1.3rem;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.358),
    0 0 1px rgba(255, 255, 255, 0.615), 0 0 1px #ffffff61, 0 0 6px #ffffff3a,
    0 0 0px #0073e6, 0 0 3px #0073e682, 0 0 11px #ffffff91;
}

.buttonTitle {
  padding: 0.5rem 2rem;
  border: 3px solid #fff;
  border-radius: 2rem;
}

.containerTextTitle {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
}

.containerTextTitleItem{
  width: 100%;
  // line-height: 4.5rem;
}

.containerTextTitle span {
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(255, 255, 255);
  word-spacing: 0.4rem;
  text-shadow: none;
  border: none;
}

.titlePageScroll {
  margin-top: -3rem;
  margin-bottom: 4rem;
  font-size: 2rem;
}

.servicesPageContainer {
  position: absolute;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  // background-color: #f2ff00;
  min-height: 100%;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 70vw;
}

.servicesCardContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.cardServicesRelative {
  position: relative;
  width: 70%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 2rem;
  overflow: hidden;
  border-radius: 1rem;
}
.cardServices {
  padding: 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  background-color: rgba(103, 103, 103, 0.17);
  border-radius: 1rem;
  cursor: default;
}

.backdropColor {
  background-color: #00f7ffb8;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 100px);
  // left: calc(50% - 50px);
  margin: 0 auto;
  box-shadow: 0 0 123px 50px #00f7ff !important;
}
.backdropColor1 {
  background-color: #f717c7b8;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: calc(0% - 0px);
  left: calc(100% - 100px);
  margin: 0 auto;
  box-shadow: 0 0 123px 50px #f717d9 !important;
}

.backdropColor2 {
  background-color: #e5ff00b8;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 0px);
  // left: calc(50% - 10px);
  margin: 0 auto;
  box-shadow: 0 0 123px 50px #ddff00 !important;
}

.backdropColor3 {
  background-color: #00ff04b8;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: calc(100% - 50px);
  left: calc(100% - 50px);
  margin: 0 auto;
  box-shadow: 0 0 123px 50px #00ff04 !important;
}

.titleCardServices {
  font-size: 2rem;
  width: fit-content;
  border-bottom: 3px solid #fff;
}
.descriptionCardServices {
  margin-top: 1rem;
  font-size: 0.9rem;
  // margin-inline-start: 1rem;
}
.itemDescriptionServices {
  font-family: fontRegular;
}

.rightSectionServices {
  // margin-top: 15%;
  display: flex;
  flex-direction: column;
  gap: 10vh;
  width: 50%;
  margin-top: -10vh;
}

.leftSectionServices {
  margin-top: 10vh;

  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 10vh;
  width: 50%;
}
.cardServicesTop {
  display: flex;
  width: 100%;
  height: 3.5rem;
  justify-content: space-between;
}

.cardServicesIcon {
  height: 3.5rem;
}

.productsContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.productsDContainer {
  display: flex;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  // padding-bottom: 3rem;
}


.productsPageContainer {
  position: relative;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  // background-color: #f2ff00;
  min-height: 100%;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 70vw;
}
.productsPageContainer1 {
  position: relative;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  // background-color: #f2ff00;
  min-height: 100%;
  justify-content: center;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 70vw;
}

.aboutPageContainer {
  position: relative;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  // background-color: #f2ff00;
  min-height: 100%;
  // justify-content: center;
  margin: 0 auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 70vw;
  overflow-y: scroll;
}

.topProductsParent {
  position: absolute;
  // width: 100%;
  display: flex;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.titlePageScrollProducts {
  margin-top: 4rem;
  // margin-bottom: 4rem;
  font-size: 2rem;
}

.productsListContainer {
  display: flex;
  flex-direction: column;
  margin-top: 3.5vh;
  justify-content: center;
  width: 100%;
}

.productsList {
  width: 98%;
  // height: 7rem;
  // overflow-y: scroll;
}

.productsItem:nth-child(1) {
  border-top: none;
  border-top-left-radius: 10px;
}
.productsItem:nth-last-child(1) {
  border-bottom: 2px solid #ffffff6b;
  border-bottom-left-radius: 10px;
}

.productsItemPartFirst {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-inline-start: 1rem;
}

.productsItem {
  border-top: 2px solid #ffffff6b;
  border-left: 2px solid #ffffff6b;
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
  // padding: 0 2rem;
}

.productsItem:hover > .productsItemPartFirst {
  .productsItemTitle {
    font-size: 1.5rem;
    text-shadow: 0 0 1px rgba(255, 255, 255, 0.358),
      0 0 1px rgba(255, 255, 255, 0.615), 0 0 1px #ffffff61, 0 0 6px #ffffff3a,
      0 0 0px #0073e6, 0 0 3px #0073e682, 0 0 11px #ffffff91;
    transition: all 0.05s linear;
  }
}

.productsItemNumber {
  font-family: fontRegular;
  font-size: 1.3rem;
}
.productsItemTitle {
  font-size: 1.3rem;
}

.productsItemLink {
  font-family: fontRegular;
  font-size: 0.9rem;
  // width: 15rem;
  display: flex;
  justify-content: flex-end;
  color: rgba(255, 255, 255, 0.621);
}

.topProductsContainer {
  display: flex;
  width: 90%;
  height: 100%;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
}

.topProductsImg {
  width: 40%;
  display: flex;
  //  height: 35rem;
  justify-content: flex-end;
}

.topProductsImg img {
  display: flex;
  justify-content: flex-end;
  // width: 15rem;
  height: 70vh;
}

.topProductsText {
  width: 60%;
  height: 65vh;
  // margin-top: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topProductsTitle {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  border-bottom: 3px solid #fff;
  width: fit-content;
}

.topProductsDescription {
  font-family: fontRegular;
  line-height: 2rem;
  text-align: justify;
}

.linkButtonContainer {
  display: flex;
  margin-top: 5rem;
  gap: 3rem;
  font-family: fontRegular;
}

.linkButton {
  padding: 0.5rem 3rem;
  border-radius: 5px;
  border: 3px solid #fff;

  // background: linear-gradient(328.78deg, #030086 14.45%, #bd6177 84.36%),
  //   linear-gradient(301.28deg, #209b4a 0%, #7000ff 100%),
  //   radial-gradient(100% 138.56% at 100% 0%, #d50000 0%, #00ffe0 100%),
  //   radial-gradient(100% 148.07% at 0% 0%, #d50000 0%, #00ffff 100%);
  // background-blend-mode: soft-light, overlay, difference, normal;
}
.arrowListProducts {
  height: 1.3rem;
}
.productsListSections {
  display: flex;
  justify-content: space-between;
  position: relative;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-top: 2px solid #ffffff6b;
  border-right: 2px solid #ffffff6b;
}
.productsImgHoverContainer {
  position: relative;
  width: 50%;
}
.productsImgHover {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.productsImgHover img {
  display: flex;
  justify-content: center;
  // margin-top: 2rem;
}

.productsImgHoverPc {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: flex-end;

  // width: 40%;
  bottom: 0;
  left: 0;
}

.productsImgHoverPc img {
  // margin-top: 17rem;
  margin-inline-end: 2rem;
  height: 35vh;
  border-radius: 10px;
}

.logoProducts {
  // opacity: 0;
  animation: imageFade 10s;
}

@keyframes imageFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.productsLogoShow {
  position: absolute;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.productsLogoHide {
  position: absolute;
  opacity: 0;
}

.productsLogoLeave {
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 1s ease-in-out;
  -moz-transition: opacity 1s ease-in-out;
  -o-transition: opacity 1s ease-in-out;
  transition: opacity 1s ease-in-out;
}

.agileContainer {
  width: 100%;
  // height: 100%;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  // background-color: rgba(255, 0, 0, 0.197);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-top: 4rem;
  position: absolute;
  z-index: 3;
}

.agileShapeContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  gap: 0.5rem;
}

.agileShape {
  // padding: 3rem 3rem;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.agileFake {
  width: 10rem;
  height: 10rem;
}
.agileCircle {
  height: 10rem;
  width: 10rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #99999922;
  backdrop-filter: blur(10px);

  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
}

.agileCircle img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4rem;
}

.agileText {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  font-size: 1.2rem;
  // font-family: fontRegular;
}

.agileRelative {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 20;
  overflow: hidden;
  border-radius: 100%;
  width: 10rem;
}

.agileRelative:hover {
  .backdropColorAgile {
    background-color: #ff00dd50;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    position: absolute;
    // bottom: calc(50% - 5px);
    bottom: 0;
    // top: calc(100% - 0px);
    display: initial;
    left: calc(50% - 7px);
    // margin: 0 auto;
    z-index: 1;
    box-shadow: 0 0 50px 40px #ff00dd5e;
    opacity: 1;
    transition: all 0.3s linear;
  }
}

.agileLineArrowActive1 {
  width: 100%;
  height: 0.5rem;
  border-radius: 10px;
  transition: all 0.3s linear;
  background-color: #ff00dda1 !important;
  box-shadow: 0 0 9px 6px #ff00dd5e;
}

.agileLineArrowActive2 {
  width: 100%;
  height: 0.5rem;
  border-radius: 10px;
  transition: all 0.3s linear;
  background-color: #00f7ffa1 !important;
  box-shadow: 0 0 9px 6px #00f7ff50;
}

.agileLineArrowActive3 {
  width: 100%;
  height: 0.5rem;
  border-radius: 10px;
  transition: all 0.3s linear;
  background-color: #e5ff00a1 !important;
  box-shadow: 0 0 9px 6px #e5ff0050;
}

.agileLineArrowActive4 {
  width: 100%;
  height: 0.5rem;
  border-radius: 10px;
  transition: all 0.3s linear;
  background-color: #a200ffa1 !important;
  box-shadow: 0 0 9px 6px #a200ff50;
}

.agileLineArrowActive5 {
  width: 100%;
  height: 0.5rem;
  border-radius: 10px;
  transition: all 0.3s linear;
  background-color: #00ff04a1 !important;
  box-shadow: 0 0 9px 6px #00ff0450;
}

.backdropColorAgile {
  opacity: 0;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  backdrop-filter: blur(10px) !important;
  padding: 0.5rem 1rem;
  background-color: #8b8b8b34 !important;
  border-radius: 10px !important;
}

.agileRoadMap {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 4rem;
  gap: 1rem;
}

.agileArrowItem {
  display: flex;
  width: 25%;
  align-items: center;
}
.agileArrow img {
  height: 3rem;
  display: flex;
  align-items: center;
}

.arrowRotate {
  rotate: 180deg;
}

.agileLineArrow {
  width: 100%;
  height: 0.5rem;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.229);
  // background-image: linear-gradient(
  //   to right,
  //   rgb(255, 255, 255) 33%,
  //   rgba(244, 241, 241, 0) 0%
  // );
  // background-position: bottom;
  // background-size: 20px 3px;
  // background-repeat: repeat-x;
}

.contactUscontainer {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 7rem;
}

.contactUsPart1 {
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.contactUsPart2 {
  width: 60%;
  display: flex;
}

.emailContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.contactUsPart2 form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-inline-start: 4rem;
  gap: 1rem;
}

.contactUsPart2 form input {
  width: 80%;
  border-radius: 10px;
  background: transparent;
  border: 2px #ffffff39 solid;
  height: 4rem;
  font-size: 1.3rem;
  color: #fff;
  backdrop-filter: blur(5px);
  background-color: rgba(157, 157, 157, 0.086);
  padding: 0 1rem;
  font-family: fontRegular;
}

.contactUsPart2 form textarea {
  width: 80%;
  height: 10rem;
  background-color: rgba(157, 157, 157, 0.086);
  border: 2px solid #ffffff39;
  border-radius: 10px;
  margin-bottom: 1rem;
  backdrop-filter: blur(10px);
  color: #fff;
  font-size: 1.3rem;
  resize: none;
  overflow: hidden;
  scrollbar-arrow-color: pink;
  scrollbar-base-color: red;
  scrollbar-darkshadow-color: blue;
  scrollbar-highlightcolor: orange;
  scrollbar-shadow-color: green;
  outline: none;
  padding: 1rem 1rem;
  font-family: fontRegular;
}

.submitEmail {
  width: 30% !important;
  font-size: 1.2rem;
  height: 3rem !important;
  border: none !important;
  color: #fff;
  font-family: fontRegular;
  // background: linear-gradient(328.78deg, #030086 14.45%, #bd6177 84.36%),
  //   linear-gradient(301.28deg, #209b4a 0%, #7000ff 100%),
  //   radial-gradient(100% 138.56% at 100% 0%, #d50000 0%, #00ffe0 100%),
  //   radial-gradient(100% 148.07% at 0% 0%, #d50000 0%, #00ffff 100%) !important;
  // background-blend-mode: soft-light, overlay, difference, normal !important;
  border: 2px solid rgba(255, 255, 255, 0.563) !important;
}

.contactUsPart2 form input:autofill {
  background-color: transparent !important;
}
.contactUsPart2 form input:-webkit-autofill {
  background-color: transparent;
}

.contactUsPart2 form input:focus {
  box-shadow: rgba(255, 255, 255, 0.758) 0px 0px 20px -7px;
  background-color: transparent !important;
  outline: none;
  color: #fff;
}

.contactUsPart2 form textarea:focus {
  box-shadow: rgba(255, 255, 255, 0.758) 0px 0px 20px -7px;
  background-color: transparent !important;
  outline: none;
  color: #fff;
}

.contactPart1Title {
  font-family: fontRegular;
  font-size: 2rem;
}

.contactPart1Des {
  font-size: 4rem;
}

.contactPart1Email {
  font-family: fontRegular;
  font-size: 1.2rem;
}

.lineContactUs {
  // background-color: red;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: fontRegular;
  gap: 0.5rem;
}

.linec {
  background-color: #ffffff54;
  width: 2px;
  height: 100%;
}

.hirePageContainer {
  margin: 3rem 0 1rem 0;
  width: 100%;
  height: 70%;
  overflow-y: scroll;
}

.hirePageContainer::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
  border-radius: 50px;
}
.hirePageContainer::-webkit-scrollbar-thumb {
  background-color: #ffffff59;
  width: 3px;
  border-radius: 50px;
}

.hireNavbarItemSection {
  display: flex;
  justify-content: center;
  font-family: fontRegular;
}

.titlePageHire {
  margin-top: 10rem;
  font-size: 2rem;
}
.hireContainer {
  // margin-top: 3rem;
  width: 99%;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.hireCardContainer {
  height: 7rem;
  border: 1px solid #fff;
  width: 45%;
  padding: 1rem;
  display: flex;
  border-radius: 7px;
  flex-direction: column;
  justify-content: space-between;
}
.hireCardContainer:hover {
  transition: ease 0.3s;
  background-color: #ffffff2a;
  backdrop-filter: blur(5px);
}

.hireCardTopsSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.hireCardTitle {
  font-size: 1rem;
  border-bottom: 2px solid #fff;
}

.hireCardBtn {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  border-bottom: 2px solid #ffffff44;
}
.hireCardBtn span {
  font-family: fontRegular;
  font-size: 0.9rem;
}

.hireCardBtn img {
  height: 0.9rem;
}

.hireCardAddres {
  width: 100%;

  display: flex;
  justify-content: flex-end;
}

.hireCardAddresText {
  font-family: fontRegular;
  font-size: 0.8rem;
  text-align: justify;
  width: fit-content;
  background-color: #ffffff1f;
  padding: 0.2rem 0.5rem;
  border-radius: 50px;
}

.hireCardDescription {
  font-family: fontRegular;
  font-size: 0.83rem;
  text-align: justify;
}

.hireApplyDescription {
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: fontRegular;
  text-align: justify;
  line-height: 2.5rem;
  white-space: pre-wrap;
  border-bottom: 1px solid #fff;
  margin-bottom: 2rem;
  padding-bottom: 2rem;
}
.hireApplyDescriptionTitle {
  font-size: 1.3rem;
  font-family: fontBold;
}
.aboutUsImgContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 3rem;
}

.hireApplyForm {
  margin-bottom: 4rem;
  width: 100%;
}

.hireApplyFormLeftSection {
  margin-top: 2rem;
  display: flex;
  gap: 0.3rem;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.hireApplyFormInputDeep {
  background-color: #ffffff23;
  backdrop-filter: blur(5px);
  border: 1px solid #fff;
  height: 1.5rem;
  width: 20rem;
  padding: 0.5rem;
  outline: none;
  color: #fff;
  font-family: fontRegular;
}

.hireApplyFormInputTitle {
  display: flex;
  justify-content: space-between;
  font-family: fontRegular;
}

.hireApplyFormInputTitleMessage {
  display: flex;
  justify-content: space-between;
  font-family: fontRegular;
  flex-direction: column;
  font-size: 0.9rem;
}
.hireApplyFormInputTitleText {
  font-size: 0.9rem;
  font-family: fontRegular;
}

.hireApplyFormInputTitleTextValidation {
  font-size: 0.65rem;
  color: #eb6f6f;
  padding: 0.1rem 0.2rem;
  border-radius: 50px;
  width: fit-content;
  margin-bottom: 0.2rem;
}
.hireApplyFormFile {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;
}

.hireApplyFormFileSelect {
  width: 20rem;
  height: 1.5rem;
  padding: 0.5rem;
  background-color: #ffffff23;
  backdrop-filter: blur(5px);
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.fileSelectIcon {
  height: 1.3rem;
}
.hireApplyTextInput {
  font-size: 0.7rem;
  font-family: fontRegular;
}

.submitApply {
  margin-top: 3rem;
  font-family: fontRegular;
}

.submitApplyText {
  border-radius: 3px;
  border: 2px solid #fff;
  width: 8rem;
  padding: 0.3rem 0;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.aboutUsImgPart1 {
  // width: 80%;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 0;
}

.aboutUsImgPart1 img {
  width: 100%;
  border-radius: 10px;
  height: 298px;
}

.sectionImg {
  height: fit-content;
}

.sectionImg2 {
  display: flex;
  flex-direction: column;
  // height: fit-content;
  gap: 0.5rem;
}

.sectionImg2 img {
  height: 145px;
}

.aboutUsImgPart2 img {
  width: 32.5%;
  border-radius: 10px;
}

.aboutUsTextContainer {
  display: flex;
  width: 100%;
  margin-top: 4rem;
  justify-content: space-between;
}

.aboutUsTextItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  gap: 1rem;
}

.aboutUsTextDescription {
  text-align: justify;
  font-family: fontRegular;
  font-size: 0.9rem;
  margin-bottom: 4rem;
}

.aboutUsTextTitle {
  border-bottom: 2px solid #fff;
  font-size: 1.5rem;
  width: 100%;
}

.scrolliconContainer {
  width: 100vw;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}
.scrollicon {
  height: 4rem;
  transform: rotate(-90deg);
}

.productDetailPageContainer {
  position: relative;
  display: flex;
  color: #fff;
  flex-direction: column;
  align-items: center;
  // background-color: #f2ff00;
  min-height: 100%;
  // justify-content: center;
  margin: 0 auto 3rem auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 70vw;
}

.bannerPtoducts {
  width: 100%;
  height: fit-content;
  margin-top: -3rem;
}

.bannerPtoducts img {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 100%;
  height: 100%;
  box-shadow: #6a6a6c25 0px 50px 100px -20px, rgba(184, 183, 183, 0.3) 0px 30px 60px -30px;
}

.detailProductContent {
  width: 100%;
  margin-bottom: 2rem;
}

.detatilProductsTextContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
}

.detailProductsTitle {
  font-size: 3rem;
  border-bottom: 3px solid #fff;
}

.detailProductsInformation {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.detailProductsleftSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.detailProductsRightSection {
  width: 50%;
  text-align: justify;
  font-family: fontRegular;
  white-space: pre-line;

}

.detailProductsleftSectionItem {
  display: flex;
  align-items: center;
  gap: 3rem;
  font-family: fontRegular;
}

.detailProductsleftSectionItem div {
  width: 8rem;
  font-weight: bold;
}


.uiShowContainer{
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 5rem;
  margin-bottom: 8rem;
}

.uiShowImg{
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.uiShowImg img{
  width: 100%;
  height: fit-content;
  border-radius: 7px;
  // box-shadow: rgba(213, 213, 215, 0.301) 0px 4px 16px, rgba(184, 184, 184, 0.05) 0px 8px 32px;
  // box-shadow: rgba(107, 108, 109, 0.3) 0px 0px 0px 3px;
  border: 3px solid #6b6c6d4d;

}